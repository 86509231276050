import "./App.scss";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import * as React from "react";
import Sandbox from "./scripts/sandbox/sandbox.js";
import { ExerciseList, Exercises } from "./scripts/reactTraining/list.js";
import AtlassianInterview from "./scripts/reactTraining/AtlassianInterview.js";
import ReactHome from "./scripts/reactTraining/home.js";
import LiveGrid from "@jcaitham/live-grid";
import { useEffect } from "react";
import { LinkBar } from "./scripts/components/LinkBar.js";
import "./assets/images/download.jpg";
import { HeroContent } from "./scripts/components/HeroSection.js";
import { ProjectsContent } from "./scripts/components/ProjectsSection.js";
import { WidgetsContent } from "./scripts/components/WidgetsSection.js";
import { Section } from "./scripts/components/Section.js";
import { default as ARtiNG } from "@jcaitham/arting-react";
import { default as Pacman } from "@jcaitham/pacman";
import { QuadTreeDemo } from "quadtreecontrol";
import { ContactMeContents } from "./scripts/components/ContactMeSection.js";
import Slideshow from "./scripts/reactTraining/SlideShow.js";
export function App() {
    return (React.createElement(BrowserRouter, null,
        React.createElement("div", { className: "mainContent" },
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: React.createElement(Home, { scrollTo: "hero" }) }),
                React.createElement(Route, { path: "/pacman", element: React.createElement(Pacman, null) }),
                React.createElement(Route, { path: "/projects", element: React.createElement(Home, { scrollTo: "projects" }) }),
                React.createElement(Route, { path: "/widgets", element: React.createElement(Home, { scrollTo: "widgets" }) }),
                React.createElement(Route, { path: "/contactme", element: React.createElement(Home, { scrollTo: "contactme" }) }),
                React.createElement(Route, { path: "/sandbox/sandbox", element: React.createElement(Sandbox, null) }),
                React.createElement(Route, { path: "/liveGrid", element: React.createElement(Section, { title: "Live Grid" },
                        React.createElement(LiveGrid, null)) }),
                React.createElement(Route, { path: "/quadTreeDemo", element: React.createElement(QuadTreeDemo, null) }),
                React.createElement(Route, { path: "/arting", element: React.createElement(ARtiNG, null) }),
                React.createElement(Route, { path: "/reactExercises", element: React.createElement(Section, { title: "React Exercises" },
                        React.createElement(ExerciseList, null)) },
                    Object.keys(Exercises).map((name) => (React.createElement(Route, { path: name, key: name, element: Exercises[name] }))),
                    React.createElement(Route, { path: "atlassianInterview", element: React.createElement(AtlassianInterview, null) }),
                    React.createElement(Route, { path: "doordashslideshow", element: React.createElement(Slideshow, null) }),
                    React.createElement(Route, { path: "home", element: React.createElement(ReactHome, null) })))),
        React.createElement(LinkBar, { collapseOnScroll: false }, links.map((link) => (React.createElement(NavLink, { to: link.path, key: link.path }, link.name)))),
        React.createElement("div", { className: "footer" })));
}
export function Home({ scrollTo }) {
    useEffect(() => {
        var _a;
        if (scrollTo) {
            const element = document.getElementById(scrollTo);
            if (element !== null) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
        else {
            (_a = document.getElementById("hero")) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { id: "hero" },
            React.createElement(HeroContent, null)),
        React.createElement(Section, { style: { backgroundColor: "" }, title: "Projects", id: "projects" },
            React.createElement(ProjectsContent, null)),
        React.createElement(Section, { title: "Widgets & Gizmos", id: "widgets" },
            React.createElement(WidgetsContent, null)),
        React.createElement(Section, { title: "Contact Me", id: "contactme" },
            React.createElement(ContactMeContents, null))));
}
const links = [
    {
        path: "/",
        name: "Home",
    },
    {
        path: "/projects",
        name: "Projects",
    },
    {
        path: "/widgets",
        name: "Widgets",
    },
    {
        path: "/contactme",
        name: "Contact Me",
    },
    {
        path: "/sandbox/sandbox",
        name: "Sandbox",
    },
];
export default App;
