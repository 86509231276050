import React, { useState } from "react";
export default function Exercise1() {
    const [nestedObject] = useState({
        taxi: "a car licensed to transport passengers in return for payment of a fare",
        food: {
            sushi: "a traditional Japanese dish of prepared rice accompanied by seafood and vegetables",
            apple: {
                Honeycrisp: "an apple cultivar developed at the MAES Horticultural Research Center",
                Fuji: "an apple cultivar developed by growers at Tohoku Research Station",
            },
        },
    });
    return (React.createElement("div", { style: { margin: "auto", width: "70%", paddingTop: 40 } },
        React.createElement(DisplayNested, { nestedObject: nestedObject })));
}
const DisplayNested = ({ nestedObject }) => {
    const list = [];
    for (const key in nestedObject) {
        let content;
        if (typeof nestedObject[key] === "string") {
            content = React.createElement("span", null, nestedObject[key]);
        }
        else {
            content = (React.createElement(DisplayNested, { nestedObject: nestedObject[key] }));
        }
        list.push(React.createElement("p", { style: { paddingLeft: 50 } },
            key,
            ": ",
            content));
    }
    return React.createElement(React.Fragment, null, list);
};
