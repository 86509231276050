import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import littleLemonLogo from "../../assets/images/littleLemonSmaller.avif";
import quadTreeLogo from "../../assets/images/quadTreeIcon.png";
import artingLogo from "../../assets/images/arting.png";
const Slideshow = () => {
    const [images, setImages] = useState([]);
    const [index, setIndex] = useState(0);
    const waitForTime = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    };
    useEffect(() => {
        const load = async () => {
            await waitForTime(2000);
            setImages([littleLemonLogo, quadTreeLogo, artingLogo]);
        };
        load();
    }, []);
    const isLoaded = images.length > 0;
    const imageTags = useMemo(() => images.map((src, idx) => (React.createElement("img", { key: idx, src: src, style: { aspectRatio: "1/1" }, height: 400, width: 400 }))), [images]);
    return (React.createElement("div", { style: { display: "flex", flexDirection: "column", width: 400 } },
        React.createElement("div", null,
            isLoaded && imageTags[index],
            !isLoaded && (React.createElement("div", { style: { width: 400, height: 400 } }, " Loading... "))),
        React.createElement("div", { style: { display: "flex" } },
            React.createElement("input", { type: "button", style: { flex: 1 }, value: "<", onClick: () => setIndex(Math.max(0, index - 1)), disabled: index <= 0 }),
            React.createElement("input", { type: "button", style: { flex: 1 }, value: ">", onClick: () => setIndex(Math.min(images.length - 1, index + 1)), disabled: index >= images.length - 1 }))));
};
export default Slideshow;
