import React from "react";
import resume from "../../assets/documents/jake-caithamer-resume.pdf";
export const ContactMeContents = () => {
    return (React.createElement("div", null,
        React.createElement("p", null,
            "If you'd like to contact me, you can reach me on\u00A0",
            React.createElement("a", { href: "https://www.linkedin.com/in/jakecaithamer/" }, "my LinkedIn profile"),
            ". I'm currently searching for a new position and would love to talk to you if you're hiring (but feel free to reach out regardless)!"),
        React.createElement("hr", null),
        React.createElement("div", { style: {
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            } },
            React.createElement("span", { style: { margin: 10 } },
                React.createElement("a", { href: resume, target: "_blank", style: {
                        fontSize: "1.17em",
                        fontFamily: "Roboto, sans-serif",
                    } }, "My Resume")),
            React.createElement("iframe", { src: resume + "#toolbar=0", width: "826", style: {
                    aspectRatio: "17/22",
                    transform: "scale(1)",
                    maxWidth: "100%",
                    marginTop: 10,
                }, title: "My current resume" }))));
};
