import React from "react";
import { Section } from "./Section.js";
import "../../styles/components/ProjectsSection.scss";
import littleLemonLogo from "../../assets/images/littleLemonSmaller.avif";
import quadTreeLogo from "../../assets/images/quadTreeIcon.png";
import artingLogo from "../../assets/images/arting.png";
import pacmanLogo from "../../assets/images/pacmanIcon.png";
import reactExercisesLogo from "../../assets/images/reactExercisesIcon.png";
import liveGridLogo from "../../assets/images/liveGridIcon.png";
import Slideshow from "@jcaitham/slideshow";
export const ProjectsSection = () => {
    return (React.createElement(Section, null,
        React.createElement(ProjectsContent, null)));
};
export const ProjectsContent = () => {
    return (React.createElement("div", { className: "projects" },
        React.createElement("div", { className: "cardList" }, projects.map((prj) => (React.createElement(DesktopCard, { project: prj })))),
        React.createElement(Slideshow, { cardWidth: 300, className: "cardSlideshow" }, projects.map((prj) => (React.createElement(DesktopCard, { project: prj }))))));
};
const DesktopCard = ({ project, style, }) => {
    return (React.createElement("article", { className: "card", onClick: () => window.open(project.link, "_blank"), style: Object.assign({}, style) },
        React.createElement("img", { src: project.image, height: "200px", width: "100%", alt: project.imgAlt }),
        React.createElement("div", { className: "content" },
            React.createElement("h2", null, project.title),
            React.createElement("p", null, project.description),
            project.githubURL !== null && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "line" }),
                React.createElement("a", { href: project.githubURL, target: "_blank", onClick: (e) => e.stopPropagation() }, "Github"))))));
};
const createProject = (title, description, image, link, githubURL) => {
    return {
        title: title,
        description: description,
        image: image,
        imgAlt: title,
        link: link,
        githubURL: githubURL,
    };
};
const projects = [
    createProject("Little Lemon", "This is a partial front-end UI for a fictional restaurant, which I built as the capstone for my React.js certification.  Focus for the course was on responsive design and form validation, and I implemented some simple bells and whistles along the way.", littleLemonLogo, "https://little-lemon.twoplustwoequalsfish.com", "https://github.com/jcaitham/little-lemon-capstone"),
    createProject("Quad Tree Demo", "Interactive demo of a quad-tree data structure.  Quad-trees are used to efficiently index points in a 2D plane, such as points on a map.  I read about them while studying for system design interviews and decided to implement one myself.", quadTreeLogo, "/quadTreeDemo", "https://github.com/jcaitham/QuadTreeDemo"),
    createProject("aRtiNG", "Fun, mostly-vanilla-Typescript project to create pseudo-random shapes and colors.  Originally just something to burn some time, but I'd like to convert it into a desktop wallpaper generator.  I'm also learning to use GitHub Actions & Packages with this project.", artingLogo, "./arting", "https://github.com/jcaitham/arting-react"),
    createProject("Pacman", "My first foray into React, using class-based React components to (attempt to) re-create this classic game.  Most of the core gameplay loop is present, but there are still bugs and lots of missing bells & whistles.", pacmanLogo, "/pacman", null),
    createProject("React Exercises", React.createElement(React.Fragment, null,
        "These are some exercises I've been doing to help myself learn React. They're based on\u00A0",
        React.createElement("a", { href: "https://medium.com/@justin.sherman", onClick: (e) => e.stopPropagation() }, "some coding challenges posted by Justin Sherman on his blog.")), reactExercisesLogo, "/reactExercises", null),
    createProject("Live Grid", "One of my Bloomberg interviewers asked me to design a grid control that updates on the fly with fresh data, and after the interview I decided to try out the design I proposed.  This is still relatively early in development, but so far it seems to be working well.", liveGridLogo, "/liveGrid", "https://github.com/jcaitham/live-grid"),
];
