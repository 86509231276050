import React, { useContext, useEffect, useState } from "react";
const UserListContext = React.createContext({});
export default function Exercise5() {
    const [userState, setUserState] = useState({
        Bob: true,
        Gary: true,
        Jessica: true,
        Sam: true,
        Eric: true,
    });
    return (React.createElement(UserListContext.Provider, { value: { userState, setUserState } },
        React.createElement(UserList, null)));
}
const UserList = () => {
    const { userState, setUserState } = useContext(UserListContext);
    useEffect(() => {
        const updateStatus = () => {
            const index = Math.floor(Object.keys(userState).length * Math.random());
            const user = Object.keys(userState)[index];
            userState[user] = !userState[user];
            setUserState(Object.assign({}, userState));
        };
        const interval = setInterval(updateStatus, 2000);
        return () => clearInterval(interval);
    }, [userState, setUserState]);
    const getDotStyle = (online) => {
        return {
            borderRadius: "10px",
            height: "20px",
            width: "20px",
            marginLeft: "5px",
            backgroundColor: online ? "green" : "red",
        };
    };
    return (React.createElement("div", null, Object.keys(userState).map((user) => (React.createElement("div", { style: { display: "flex" } },
        React.createElement("div", null, user),
        React.createElement("div", { style: getDotStyle(userState[user]) }))))));
    // YOUR CODE HERE
};
