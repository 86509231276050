import React, { useCallback, useEffect, useMemo } from "react";
import { useRef } from "react";
import "./cycles.scss";
// start fading out a line
const startFade = (line) => {
    line.classList.add("fade");
};
// delete an old line from the DOM
const deleteLine = (line) => {
    line.remove();
};
export const Cycles = () => {
    const containerRef = useRef(null);
    const timeoutRef = useRef(undefined);
    const lineRef = useRef({
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 100,
    });
    const colorRef = useRef({
        color: [255 * Math.random(), 255 * Math.random(), 255 * Math.random()],
        colorIdx: 0,
    });
    const cycleRef = useRef({ cyclePosition: 0, directionsIdx: 0, time: 0 });
    // start and end positions of the next line we draw
    // how far we'll increment the start & end point of the lines each tick
    const increment = Math.floor(Math.random() * 3 + 1);
    const directions = useMemo(() => [
        [0, 1, 1, 0],
        [1, 0, 0, -1],
        [0, -1, -1, 0],
        [-1, 0, 0, 1],
    ], []);
    const oneTick = useCallback(() => {
        const newLine = document.createElementNS("http://www.w3.org/2000/svg", "line");
        let { startX, startY, endX, endY } = lineRef.current;
        let { color, colorIdx } = colorRef.current;
        newLine.setAttribute("x1", String(startX) + "%");
        newLine.setAttribute("y1", String(startY) + "%");
        newLine.setAttribute("x2", String(endX) + "%");
        newLine.setAttribute("y2", String(endY) + "%");
        newLine.style.stroke = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
        // progress the color
        color[colorIdx % 3] = Math.max(0, color[colorIdx % 3] - 10);
        color[(colorIdx + 1) % 3] = Math.min(255, color[(colorIdx + 1) % 3] + 10);
        if (color[colorIdx % 3] <= 0 || color[(colorIdx + 1) % 3] >= 255) {
            colorIdx++;
        }
        colorRef.current = { color, colorIdx };
        // append our new line to the DOM
        if (containerRef.current) {
            containerRef.current.appendChild(newLine);
        }
        // increment position of line for the next tick
        const curDir = directions[cycleRef.current.directionsIdx];
        startX += curDir[0] * increment;
        startY += curDir[1] * increment;
        endX += curDir[2] * increment;
        endY += curDir[3] * increment;
        lineRef.current = { startX, startY, endX, endY };
        let { cyclePosition, directionsIdx, time } = cycleRef.current;
        cyclePosition += increment;
        if (cyclePosition >= 100) {
            cyclePosition = 0;
            directionsIdx = (directionsIdx + 1) % directions.length;
        }
        // start timeouts to fade and delete the line after some time
        setTimeout(() => startFade(newLine), 2500);
        setTimeout(() => deleteLine(newLine), 4500);
        // cycle the time until the next tick, just to give some variety
        const timeUntilNextTick = 10 * Math.sin(cycleRef.current.time++ / 60) + 30;
        cycleRef.current = { cyclePosition, directionsIdx, time };
        // start a timer until we add the next line
        timeoutRef.current = window.setTimeout(oneTick, timeUntilNextTick);
    }, [increment, directions]);
    useEffect(() => {
        timeoutRef.current = window.setTimeout(oneTick, 30);
        return () => clearTimeout(timeoutRef.current);
    }, [oneTick]);
    return (React.createElement("svg", { width: "100%", height: "100%", ref: containerRef, className: "circlesWidget" }));
};
export const CyclesDemo = () => {
    return (React.createElement("div", { style: { width: "100%", aspectRatio: "1/1" } },
        React.createElement(Cycles, null)));
};
