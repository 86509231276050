import React, { useEffect, useRef } from "react";
import "./WaterFill.css";
//const function WaterFillDecorator = <T>(Component: ComponentType<T>) =>
export function WaterFillDecorator(Component, backgroundColor) {
    const WrappedComponent = (props) => {
        const outerDivRef = useRef(null);
        const overlappingRect = useRef(null);
        useEffect(() => {
            if (outerDivRef.current && overlappingRect.current) {
                if (backgroundColor !== undefined) {
                    overlappingRect.current.style.backgroundColor = backgroundColor;
                }
                else {
                    overlappingRect.current.style.backgroundColor = window
                        .getComputedStyle(outerDivRef.current)
                        .getPropertyValue("background-color");
                }
            }
        });
        return (React.createElement("div", { className: "waterFilled", style: Object.assign({}, props.style), ref: outerDivRef },
            React.createElement("div", { title: "hello world", className: "waterLayer" },
                React.createElement("div", { className: "content" }, Component)),
            React.createElement("div", { className: "centerWater" },
                React.createElement("div", { className: "water", ref: overlappingRect, style: { backgroundColor: backgroundColor } }))));
    };
    return WrappedComponent;
}
const Test = () => {
    return (React.createElement("div", { style: {
            height: "100%",
            aspectRatio: "1/1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        } }, "hello world"));
};
export const WaterFilledTest = WaterFillDecorator(React.createElement(Test, null), "#F8F8F8");
