import React, { useEffect, useRef, useState } from "react";
import "./animationAPIDemo.scss";
import { ReactComponent as PlayIcon } from "../../assets/images/playIcon.svg";
import { ReactComponent as PauseIcon } from "../../assets/images/pauseIcon.svg";
/**
 * Create a recursive DOM tree of squares, each of which rotates within the bounds of its parent with a CSS animation.  The "levels" param
 * determines how many layers of squares we will show.
 */
const RecursiveRectangles = ({ style, levels, paused, }) => {
    const container = useRef(null);
    const animations = useRef([]);
    // add animations to all of our elements
    useEffect(() => {
        var _a, _b;
        const shrinkings = (_a = container.current) === null || _a === void 0 ? void 0 : _a.getElementsByClassName("shrinking");
        if (!shrinkings) {
            return;
        }
        const shrinkingKeyframes = [
            { transform: "scale(1.0)" },
            { transform: "scale(.825)" },
        ];
        const shrinkingOptions = {
            duration: 5000,
            iterations: Infinity,
            easing: "cubic-bezier(.09,.27,1,1)",
            direction: "alternate",
        };
        for (const shrinking of shrinkings) {
            animations.current.push(shrinking.animate(shrinkingKeyframes, shrinkingOptions));
        }
        const spinnings = (_b = container.current) === null || _b === void 0 ? void 0 : _b.getElementsByClassName("spinning");
        if (!spinnings) {
            return;
        }
        const spinningKeyframes = [
            { transform: "rotate(0deg)" },
            { transform: "rotate(15deg)" },
        ];
        const spinningOptions = {
            duration: 5000,
            iterations: Infinity,
            easing: "linear",
            direction: "alternate",
        };
        for (const spinning of spinnings) {
            animations.current.push(spinning.animate(spinningKeyframes, spinningOptions));
        }
    }, []);
    // whenever the "paused" prop changes, update the animation status of all our animations
    useEffect(() => {
        animations.current.forEach((a) => (paused ? a.pause() : a.play()));
    }, [paused]);
    return (React.createElement("div", { className: "container", style: Object.assign(Object.assign({}, style), { border: "1px solid black" }), ref: container },
        React.createElement(RecursiveLayer, { remaining: levels })));
};
/**
 * A single layer (single rotating square) within the recursive squares visual
 */
const RecursiveLayer = ({ remaining }) => {
    return (React.createElement("div", { className: "shrinking" },
        React.createElement("div", { className: "spinning" }, remaining > 0 && React.createElement(RecursiveLayer, { remaining: remaining - 1 }))));
};
/**
 * Demonstration of the spinning squares, including a pause button which will pause all animations
 */
export const AnimationAPIDemo = () => {
    const [paused, setPaused] = useState(false);
    return (React.createElement("div", { style: {
            width: "100%",
            aspectRatio: "1/1",
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
        } },
        React.createElement(RecursiveRectangles, { levels: 15, paused: paused, style: { width: "calc(50% - 2px)", transform: "scaleX(-1)" } }),
        React.createElement(RecursiveRectangles, { levels: 15, paused: paused, style: { width: "calc(50% - 2px)" } }),
        React.createElement(RecursiveRectangles, { levels: 15, paused: paused, style: { width: "calc(50% - 2px)" } }),
        React.createElement(RecursiveRectangles, { levels: 15, paused: paused, style: { width: "calc(50% - 2px)", transform: "scaleX(-1) scaleY(1)" } }),
        React.createElement("div", { className: "overlay" },
            React.createElement("div", { className: "iconWrapper", style: { display: paused ? "" : "none" } },
                React.createElement(PlayIcon, { style: { width: 50, height: 50 }, className: "icon", onClick: () => setPaused(!paused) })),
            React.createElement("div", { className: "iconWrapper", style: { display: paused ? "none" : "" } },
                React.createElement(PauseIcon, { style: { width: 50, height: 50 }, className: "icon", onClick: () => setPaused(!paused) })))));
};
