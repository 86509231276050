import React, { useState } from "react";
const INITIAL_LIST = {
    "Organize closet": [
        { "Donate old clothes and shoes": false },
        { "Buy new shelf": false },
        { "Put in shelf by color": false },
    ],
    "Finish homework": [
        { "Finish math homework": false },
        { "Finish science homework": false },
        { "Finish Reactjs homework": false },
    ],
    "Achieve nirvana": [
        { "Meditate a little": false },
        { "Gain some wisdom": false },
    ],
};
export default function Exercise6() {
    return React.createElement(Checklist, null);
}
const Checklist = () => {
    const [list] = useState(INITIAL_LIST);
    return (React.createElement("div", null, Object.keys(list).map((task) => (React.createElement(OneTask, { title: task, array: list[task] })))));
    // YOUR CODE HERE
};
const OneTask = ({ title, array, }) => {
    const [taskArray, setTaskArray] = useState(array);
    const completeAtIndex = (index) => {
        const newTaskArray = [];
        for (let i = 0; i < taskArray.length; i++) {
            if (i === index) {
                const obj = taskArray[i];
                const text = Object.keys(obj)[0];
                obj[text] = true;
            }
            newTaskArray.push(taskArray[i]);
        }
        setTaskArray(newTaskArray);
    };
    return (React.createElement("div", { style: { display: "flex", flexWrap: "wrap", width: "400px" } },
        React.createElement("h2", { style: { width: "100%" } }, title),
        React.createElement("div", { style: { width: "50%", display: "flex", flexDirection: "column" } },
            React.createElement("h3", null, "Not yet completed"),
            taskArray.map((task, index) => {
                const text = Object.keys(task)[0];
                if (!task[text]) {
                    return (React.createElement("div", { onClick: () => completeAtIndex(index), style: { cursor: "pointer" } }, text));
                }
                return React.createElement(React.Fragment, null);
            })),
        React.createElement("div", { style: { width: "50%", display: "flex", flexDirection: "column" } },
            React.createElement("h3", null, "Completed"),
            taskArray.map((task) => {
                const text = Object.keys(task)[0];
                if (task[text]) {
                    return React.createElement("div", null, text);
                }
                return React.createElement(React.Fragment, null);
            }))));
};
// (PROBABLY) MORE OF YOUR CODE HERE
