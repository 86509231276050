import React from "react";
import { useState } from "react";
export default function Exercise2() {
    return React.createElement(RobotList, null);
}
const RobotList = () => {
    const [list, setList] = useState([]);
    const [query, setQuery] = useState("");
    const onQueryChange = (e) => {
        setQuery(e.target.value);
    };
    const onSubmit = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        if (query.length > 0) {
            setList([...list, query]);
            setQuery("");
        }
    };
    const removeAt = (index) => {
        const newArr = [];
        for (let i = 0; i < list.length; i++) {
            if (i !== index) {
                newArr.push(list[i]);
            }
        }
        setList(newArr);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            } },
            React.createElement("form", { onSubmit: onSubmit },
                React.createElement("input", { type: "text", value: query, onChange: onQueryChange, placeholder: "enter a name", autoFocus: true }),
                React.createElement("input", { type: "submit", value: "Go!" })),
            React.createElement(ImageList, { nameList: list, removeRobot: removeAt }))));
};
const ImageList = ({ nameList, removeRobot, }) => {
    let index = 0;
    return (React.createElement("div", { style: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "600px",
        } }, nameList.map((name) => (React.createElement("img", { width: "200px", style: { cursor: "pointer" }, title: "click to remove", src: "https://robohash.org/" + name, alt: "robot friend", onClick: removeRobot.bind(this, index++) })))));
};
