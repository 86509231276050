import React from "react";
import "./sandbox.scss";
export default class Sandbox extends React.Component {
    constructor(props) {
        super(props);
        this.imageDimension = 200;
        this.slidesEl = null;
        this.currentShift = 0;
        this.imgs = [
            {
                url: "https://images.unsplash.com/photo-1611915387288-fd8d2f5f928b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2080&q=80",
                description: "cat picture",
            },
            {
                url: "https://images.unsplash.com/photo-1571988840298-3b5301d5109b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
                description: "a second cat picture",
            },
        ];
        this.currentImageIndex = 0;
        this.imageMetadata = [];
        this.state = { message: "", element: null };
    }
    render() {
        return (React.createElement("div", { className: "sandbox" },
            React.createElement("div", { className: "flexContainer" },
                React.createElement("div", { className: "card flexContainer" },
                    React.createElement("button", { onClick: (event) => this.doSomething(event), className: "cardRow" }, "Make a fetch request")),
                React.createElement("div", { className: "card flexContainer" },
                    React.createElement("button", { onClick: (event) => this.doSomethingWithPromises(event), className: "cardRow" }, "Do something with promises")),
                React.createElement("div", { className: "card flexContainer" },
                    React.createElement("button", { onClick: (event) => this.doAFetchPost(event), className: "cardRow" }, "Make a fetch request, but with a POST")),
                React.createElement("div", { className: "card flexContainer" },
                    React.createElement("button", { onClick: (event) => this.buildVanillaTSControl(event), className: "cardRow" }, "Build Vanilla TS Tool"))),
            React.createElement("textarea", { className: "textArea", defaultValue: this.state.message }),
            React.createElement("div", { className: "textArea" }, this.state.element),
            React.createElement("div", { className: "textArea", id: "vanillaTSArea" })));
    }
    doSomething(_event) {
        fetch("https://reqres.in/api/users")
            .then((data) => {
            return data.json();
        })
            .then((data) => {
            this.setState({
                message: JSON.stringify(data),
                element: this.buildTable(data),
            });
        });
    }
    doAFetchPost(_event) {
        fetch("https://reqres.in/api/users", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ first_name: "Beer", last_name: "Pong" }),
        })
            .then((data) => data.json())
            .then((data) => this.setState({ message: JSON.stringify(data) }));
    }
    buildTable(json) {
        let rows = [];
        for (const person of json.data) {
            rows.push(React.createElement("tr", null,
                React.createElement("td", null, person.id),
                React.createElement("td", null, person.first_name),
                React.createElement("td", null, person.last_name),
                React.createElement("td", null, person.email),
                React.createElement("td", null,
                    React.createElement("img", { alt: "avatar alternate", src: person.avatar, height: "40px", width: "40px" }))));
        }
        return (React.createElement("table", { className: "dataTable" },
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "ID"),
                    React.createElement("th", null, "First"),
                    React.createElement("th", null, "Last"),
                    React.createElement("th", null, "Email"),
                    React.createElement("th", null, "Avatar")),
                rows)));
    }
    doSomethingWithPromises(event) {
        let fastPromise = this.buildPromise(1000);
        let slowPromise = this.buildPromise(2000);
        slowPromise.then((message) => {
            this.setState({ message: message });
        });
        fastPromise.then((message) => {
            this.setState({ message: message });
        });
        this.setState({
            message: "updating immediately, to show that promises are non-blocking",
        });
    }
    buildPromise(delay) {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve("waited " + delay + " milliseconds"), delay);
        });
    }
    buildVanillaTSControl(event) {
        const el = document.getElementById("vanillaTSArea");
        el.classList.add("centerContent");
        el.innerHTML = "";
        const slideWindow = document.createElement("div");
        slideWindow.classList.add("slideWindow");
        this.slidesEl = document.createElement("div");
        this.slidesEl.classList.add("slideShow");
        slideWindow.appendChild(this.slidesEl);
        this.buildAndAppendButtons(slideWindow);
        el.appendChild(slideWindow);
        this.getAndPutPicture(0, this.slidesEl);
        this.getAndPutPicture(1, this.slidesEl);
    }
    buildAndAppendButtons(element) {
        const leftButton = document.createElement("div");
        leftButton.innerHTML = "<";
        leftButton.classList.add("slideShowButton");
        leftButton.style.left = "0px";
        leftButton.addEventListener("click", () => this.shiftSlideshow(true));
        const rightButton = document.createElement("div");
        rightButton.innerHTML = ">";
        rightButton.classList.add("slideShowButton");
        rightButton.style.right = "0px";
        rightButton.addEventListener("click", () => this.shiftSlideshow(false));
        element.appendChild(leftButton);
        element.appendChild(rightButton);
    }
    shiftSlideshow(left) {
        if (!this.slidesEl ||
            this.currentImageIndex === null ||
            (this.currentImageIndex === 0 && left) ||
            (this.currentImageIndex === this.imageMetadata.length - 1 && !left)) {
            return;
        }
        const shift = this.imageMetadata[this.currentImageIndex].width * (left ? 1 : -1);
        this.currentShift += shift;
        this.currentImageIndex += left ? -1 : 1;
        this.currentImageIndex = Math.max(this.currentImageIndex, 0);
        this.currentImageIndex = Math.min(this.currentImageIndex, this.imageMetadata.length - 1);
        this.slidesEl.style.left = this.currentShift + "px";
    }
    async getAndPutPicture(id, element) {
        const waitingMessage = document.createElement("div");
        waitingMessage.innerHTML = "waiting";
        element.appendChild(waitingMessage);
        const img = await this.fakeFetch(id);
        const imgEl = document.createElement("img");
        imgEl.classList.add("slideShowImage");
        imgEl.src = img.url;
        imgEl.onload = () => {
            this.imageMetadata[id] = { width: imgEl.width };
        };
        element.removeChild(waitingMessage);
        element.appendChild(imgEl);
    }
    fakeFetch(id) {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(this.imgs[id]), 1000);
        });
    }
}
