import React from "react";
import { useEffect } from "react";
const Exercise10 = () => {
    const ARRAY = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 8, 5, 3, 5, 7, 4, 2, 1, 2, 3, 4, 5, 6, 7, 8, 9,
        5, 4, 1, 2, 7, 9, 4, 3, 2, 9, 3, 7, 6,
    ];
    let count = 0;
    let originalImplementationCount = 0;
    const incrementElCount = (inc) => {
        count += inc;
    };
    const incrementOriginalCount = (inc) => {
        originalImplementationCount += inc;
    };
    useEffect(() => {
        document.getElementById("newCount").innerHTML =
            `Recursive Implementation ${count} DOM elements`;
        document.getElementById("originalCount").innerHTML =
            `Original Implementation ${originalImplementationCount} DOM elements`;
    });
    return (React.createElement("div", { style: { width: "1200px" } },
        React.createElement("h1", { style: { textAlign: "center" } }, "Merge Sort Splitting"),
        React.createElement("h2", { style: { textAlign: "center" }, id: "newCount" }, "Placeholder..."),
        React.createElement(DivideBeforeConquerRecursive, { array: ARRAY, numSiblings: 0, incrementElCount: incrementElCount }),
        React.createElement("h2", { style: { textAlign: "center" }, id: "originalCount" }, "Placeholder..."),
        React.createElement(DivideBeforeConquer, { array: ARRAY, incrementElCount: incrementOriginalCount })));
};
const DivideBeforeConquerRecursive = ({ array, numSiblings, incrementElCount, }) => {
    const left = array.slice(0, Math.floor(array.length / 2));
    const right = array.slice(Math.floor(array.length / 2), array.length);
    incrementElCount(3);
    return (React.createElement("div", { className: "parent", style: { display: "flex", flexDirection: "column" } },
        array.length > 0 && (React.createElement("div", { className: "text row", style: { textAlign: "center", padding: "0px 5px" } }, `[${array.join(", ")}]`)),
        (array.length > 1 || numSiblings > 1) && (React.createElement("div", { className: "children", style: { display: "flex", justifyContent: "center" } },
            React.createElement(DivideBeforeConquerRecursive, { array: left, numSiblings: right.length, incrementElCount: incrementElCount }),
            React.createElement(DivideBeforeConquerRecursive, { array: right, numSiblings: left.length, incrementElCount: incrementElCount })))));
};
const DivideBeforeConquer = ({ array, incrementElCount, }) => {
    const numElements = array.length;
    let arrays = [array];
    const levels = [];
    while (arrays.length < numElements) {
        levels.push(React.createElement(OneRow, { arrays: arrays, incrementElCount: incrementElCount }));
        const newArrays = [];
        for (const arr of arrays) {
            if (arr.length > 1) {
                newArrays.push(arr.slice(0, arr.length / 2));
                newArrays.push(arr.slice(arr.length / 2, arr.length));
            }
            else {
                newArrays.push(arr);
            }
        }
        arrays = newArrays;
    }
    levels.push(React.createElement(OneRow, { arrays: arrays, incrementElCount: incrementElCount }));
    incrementElCount(1);
    return (React.createElement("div", { style: { display: "flex", flexDirection: "column" } }, levels));
};
const OneRow = ({ arrays, incrementElCount, }) => {
    incrementElCount(1 + arrays.length);
    return (React.createElement("div", { style: { display: "flex", marginBottom: "5px" } }, arrays.map((array) => (React.createElement("div", { style: { flex: "1", textAlign: "center" } }, `[${array.join(", ")}]`)))));
};
export default Exercise10;
