import React from "react";
import "./orbits.scss";
const system = {
    radius: 50,
    orbitalDistance: 0,
    orbitalPeriod: 0,
    color: "red, orange, yellow, orange, red",
    children: [
        {
            radius: 25,
            orbitalDistance: 100,
            orbitalPeriod: 20,
            litFromCenter: true,
            children: [
                {
                    radius: 7,
                    orbitalDistance: 20,
                    orbitalPeriod: 5,
                    reverse: true,
                    children: [],
                },
                {
                    radius: 5,
                    orbitalDistance: 27,
                    orbitalPeriod: 25,
                    reverse: true,
                    children: [],
                },
                {
                    radius: 5,
                    orbitalDistance: 33,
                    orbitalPeriod: 8,
                    reverse: true,
                    children: [],
                },
            ],
        },
        {
            radius: 17,
            orbitalDistance: 50,
            orbitalPeriod: 15,
            reverse: true,
            litFromCenter: true,
            children: [],
        },
        {
            radius: 12,
            orbitalDistance: 140,
            orbitalPeriod: 100,
            litFromCenter: true,
            children: [],
        },
    ],
};
const colors = [
    "blue, green, yellow, green, blue",
    "red, yellow, orange, yellow, red",
    "black, purple, red, pink, red, purple, black",
    "pink,white,pink,white,pink,white,pink,white",
    "lightblue, blue, darkblue",
    "darkorange, orange, orangered, orange, darkorange",
    "grey, brown, red, brown, grey",
    "white, grey, black, grey, white",
    "lightgreen, green, darkgreen",
    "red, orange, yellow, green, blue, purple",
    "red, orange, yellow, green, blue, purple, blue, green, yellow, orange, red",
    "red, maroon, red",
    "blue, teal, blue",
    "green, teal, green",
    "purple, violet, purple",
    "orange, orangered, orange",
    "yellow, gold, yellow",
    "white, grey, white",
];
export const OrbitsDemo = () => {
    return React.createElement(Orbits, { system: system });
};
export const Orbits = ({ system }) => {
    return (React.createElement("div", { className: "frame" },
        React.createElement(Planet, { planet: system })));
};
const Planet = ({ planet }) => {
    const animationStyle = `orbit ${planet.orbitalPeriod}s linear infinite ${planet.reverse === true ? "reverse" : ""}`;
    const randomIndex = Math.floor(Math.random() * colors.length);
    const planetColor = Math.random() > 0.5
        ? `linear-gradient(${planet.color || colors[randomIndex]})`
        : `radial-gradient(${planet.color || colors[randomIndex]})`;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "orbitOutline center", style: {
                width: 2 * planet.orbitalDistance,
                height: 2 * planet.orbitalDistance,
            } }),
        React.createElement("div", { className: "orbitStick", style: { width: planet.orbitalDistance, animation: animationStyle } },
            React.createElement("div", { className: "planetWrapper center", style: { width: planet.radius, height: planet.radius } },
                React.createElement("div", { className: "planet", style: { backgroundImage: planetColor } }),
                planet.litFromCenter && React.createElement("div", { className: "planet sunlight center" }),
                planet.children.map((p, index) => (React.createElement(Planet, { key: index, planet: p })))))));
};
