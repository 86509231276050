import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import "../../styles/components/LinkBar.scss";
export const LinkBar = ({ children, collapseOnScroll, }) => {
    const toolbarRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        if (collapseOnScroll) {
            let scrollPosition = window.scrollY;
            const handler = () => {
                if (toolbarRef.current === null) {
                    return;
                }
                const newScrollPosition = window.scrollY;
                if (newScrollPosition > scrollPosition &&
                    newScrollPosition > toolbarRef.current.clientHeight) {
                    toolbarRef.current.style.transform = "translateY(-200px)";
                }
                else {
                    toolbarRef.current.style.transform = "translateY(0px)";
                }
                scrollPosition = newScrollPosition;
            };
            window.addEventListener("scroll", handler);
            return () => {
                window.removeEventListener("scroll", handler);
            };
        }
    });
    const linkList = React.Children.map(children, (child) => React.cloneElement(child, {
        className: `${child.props.className !== undefined ? child.props.className : ""} ${"linkEntry"}`,
        onPointerUp: () => setExpanded(false),
    }));
    return (
    // z-index is necessary to fix a weird bug where the gutters were appearing on top of the toolbar, even though the toolbar is later in the dom order
    React.createElement("div", { id: "linksContainer", className: "linksContainer", ref: toolbarRef },
        React.createElement("div", { className: "linksContent" },
            React.createElement(NavLink, { to: "/", className: "logoWrapper", tabIndex: 1 },
                React.createElement(Logo, { className: "logo", alt: "A minimalist image of a fish that links back to the homepage of the site" })),
            React.createElement("div", { id: "links", className: "linkToolbar" }, linkList),
            React.createElement("div", { id: "hamburgerLinks", className: "hamburgerLinks", onClick: () => setExpanded(!expanded) },
                React.createElement("div", { className: "hamburgerLine" }))),
        React.createElement("div", { id: "dropdownContainer", className: "dropdownContainer " + (expanded ? "" : "hidden") }, linkList)));
};
