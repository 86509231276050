import React from "react";
import { useEffect, useState } from "react";
export default function Exercise3() {
    return React.createElement(GenerateList, null);
}
const GenerateList = () => {
    const [items, setItems] = useState([]);
    const generateNewActivity = () => {
        fetch("https://www.boredapi.com/api/activity")
            .then((response) => response.json())
            .then((response) => setItems([...items, response]));
    };
    useEffect(generateNewActivity, [items]);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: generateNewActivity }, "Generate Activity"),
        React.createElement("ul", null, items.map((item) => (React.createElement(ExpandableListItem, { input: item }))))));
};
const ExpandableListItem = ({ input }) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleExpanded = () => {
        setCollapsed(!collapsed);
    };
    return (React.createElement("li", null,
        React.createElement("span", null, input.activity),
        React.createElement("button", { onClick: toggleExpanded, style: { marginLeft: "20px" } }, collapsed ? "Expand" : "Collapse"),
        React.createElement("ul", null, collapsed === false &&
            Object.keys(input).map((prop) => {
                if (prop !== "activity") {
                    return (React.createElement("li", null,
                        React.createElement("span", null, prop + ": "),
                        input[prop]));
                }
                return undefined;
            }))));
    // YOUR CODE HERE
};
