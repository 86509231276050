import React, { useEffect, useRef } from "react";
import "./TypingStuff.scss";
// this will make a CSS-only typing effect, but it only works if the text fits onto a single line, so for now it's unused
export const TypingEffectCSSOnly = ({ message }) => {
    const numCharacters = message.length;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { style: { display: "inline-block", fontFamily: "monospace" } },
            React.createElement("span", { className: "typewriter", style: {
                    animationTimingFunction: `steps(${numCharacters + 1}, jump-none)`,
                } }, message),
            React.createElement("span", { className: "cursor" }))));
};
export const TypingEffect = ({ message }) => {
    const textRef = useRef(null);
    const timerRef = useRef(undefined);
    const indexRef = useRef(0);
    useEffect(() => {
        const appendLetter = () => {
            if (textRef.current && indexRef.current < message.length) {
                textRef.current.innerHTML =
                    textRef.current.innerHTML + message.charAt(indexRef.current++);
                timerRef.current = window.setTimeout(appendLetter, 80);
            }
        };
        appendLetter();
        return () => clearTimeout(timerRef.current);
    }, [message]);
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { ref: textRef, style: { fontFamily: "monospace" } }),
        React.createElement("span", { className: "cursor" })));
};
